import React from 'react'

const route = {
    homepage: "/",
    servicespage:"/services",   
    costestimation:"/costestimation",
    aboutus:"/about",
    faq:"/faq",
    contactus:"/contact"
}


 


export default route
