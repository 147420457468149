import React from 'react';
import { render } from 'react-dom';
import App from './app.js';
import { ToastContainer } from 'react-toastify';
import { QueryClientProvider, QueryClient } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

const queryClient = new QueryClient();
const Index = () => (
  <>

    <QueryClientProvider client={queryClient}>
      <>
        <ToastContainer autoClose={2000} />
        <App />
      </>
      <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
    </QueryClientProvider>
  </>
);
render(<Index />, document.getElementById('root'));