import { NearMeRounded, PlaceTwoTone } from '@mui/icons-material';
import { Autocomplete, Box, Card, CircularProgress, colors, InputAdornment, Stack, TextField, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { useState } from 'react';
import { useQuery } from 'react-query';
import { getDDCities } from '../services/cust-service';
import useDebounce from './useDebounce';

function PlaceAutofill({ name, width, title, errMsg, onSelect, defaultValue, startIcColor, disabled, readOnly, value, onChange }) {


  const isMatch = useMediaQuery("(min-width: 650px)");
  const [inputValue, setInputValue] = useState("")
  const debouncedTxt = useDebounce(inputValue, 500);


  const { isLoading, data: apiData } = useQuery(["gps", debouncedTxt], () => getDDCities(debouncedTxt), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: debouncedTxt !== ""
  });

  const labelStyle = {
    color: errMsg && colors.red[500],
    fontWeight: "500",
    fontSize: isMatch?12:10,
    fontFamily: 'Poppins'
  }

  return <Card sx={{ width: width ? width : 500 }} >
    <Box sx={{ backgroundColor: "#ffff", borderRadius: 1.4, height: errMsg ? 80 : 50, borderLeft: '5px solid blue' }} px={2} py={1}>

      <Typography color={"primary.main"} htmlFor={title} style={labelStyle} component={'label'} >{title}</Typography>
      <Autocomplete
        inputValue={value || inputValue}
        size={"small"}
        onInputChange={(e, v) => setInputValue(v)}
        defaultValue={{ place: defaultValue || "" }}
        onChange={(e, v) => {
          onSelect && onSelect(v)
        }}

        noOptionsText={"NO OPTIONS"}
        isOptionEqualToValue={(option, value) => option?.place === (value.place)}
        getOptionLabel={(option) => Object.keys(option).length > 0 ? option?.place : ""}
        options={apiData || []}
        loading={isLoading}

        readOnly={readOnly}
        disabled={disabled}

        sx={{
          "& .MuiInputBase-root": {
            backgroundColor: "white",
            fontSize: 12,
            height:isMatch? 38:32,
            fontFamily: 'Poppins',
            fontWeight:'500'
          },
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            border: "0px solid #eee",
          },

        }}

        renderOption={(props, jsRes) => (<Stack direction={"row"} alignItems="center" gap={1} {...props} key={jsRes?.place}>
          <NearMeRounded color='disabled' />
          <Typography>{jsRes?.place}</Typography>
        </Stack>)}

        renderInput={(params) => (
          <TextField
            {...params}
            name={name}
            InputProps={{
              ...params.InputProps,

              startAdornment: (
                <InputAdornment position={"start"} >
                  <PlaceTwoTone style={{ color: startIcColor }} />
                </InputAdornment>
              ),

              endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            fullWidth
            id={title}
            error={errMsg && true}
            helperText={errMsg && errMsg}
            value={value}
            onChange={onChange}
          />
        )}
      />

    </Box>
  </Card>;
}

export default PlaceAutofill

