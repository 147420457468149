import React from 'react'
import { Stack } from '@mui/system'
import background from '../assets/images/background.png';

function bgImg({ children }) {
    return (
        <Stack style={{ backgroundImage: `url(${background})`,  backgroundSize: 'cover' ,backgroundPosition:'absolute'}}>
             {children}
        </Stack>
    )
}

export default bgImg;