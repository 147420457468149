import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Typography, Box } from '@mui/material';
import { useMediaQuery } from '@mui/material';
const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,

    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '1rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: 'white',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(3),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function Faq1() {
    const [expanded, setExpanded] = React.useState('panel');
    const isMatch = useMediaQuery("(min-width:650px)")
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const typoans = { fontFamily: isMatch ? 'Rubik' : 'Inter', fontSize: isMatch ? '14px' : '12px', variant: 'body2' ,width:isMatch?800:270};
    const typoqus = { ml: '10px', fontFamily: isMatch ? 'Rubik' : 'Inter', fontSize: isMatch ? '18px' : '14px', variant: 'body1',width:isMatch?800:270};

    return (

        <Box sx={{ width: isMatch ? '80%' : '90%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{ marginBottom: isMatch ? '20px' : '10px' }}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography sx={typoqus}>What is DLX app, and how does it work?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={typoans}>
                        Our DLX is a mobile app that connects consignors, consignees, and track operators, providing a streamlined and secure platform for managing logistics operations. Users can book shipments, track shipments in real-time, and manage their logistics operations efficiently and effectively.

                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} sx={{ marginBottom: isMatch ? '20px' : '10px' }}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <Typography sx={typoqus}>"Is your logistics application secure?",</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={typoans}>
                        "Yes, we take security seriously, and DLX is designed with robust security measures to ensure the secure delivery of shipments. Our platform includes features such as driver verification, delivery confirmation, and secure storage, providing peace of mind to both consignors and consignees.",
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} sx={{ marginBottom: isMatch ? '20px' : '10px' }}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <Typography sx={typoqus}> Can I track my shipment in real-time?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={typoans}>
                        Yes, DLX provides real-time tracking of shipments, enabling consignors, consignees, and track operators to monitor the location and status of shipments at all times.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} sx={{ marginBottom: isMatch ? '20px' : '10px' }}>
                <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                    <Typography sx={typoqus}> What types of shipments can I book on your logistics application? </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={typoans}>
                        DLX supports a wide range of shipments, including parcels, pallets, and full container loads.</Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} sx={{ marginBottom: isMatch ? '20px' : '10px' }}>
                <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                    <Typography sx={typoqus}> What if I have a problem with my shipment?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={typoans}>
                        If you encounter any issues with your shipment, our platform provides tools for reporting and resolving issues promptly.
                        Our customer support team is also available to assist you with any issues you may encounter.</Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')} sx={{ marginBottom: isMatch ? '20px' : '10px' }}>
                <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
                    <Typography sx={typoqus}> How can DLX benefit my business?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={typoans}>
                        • By employing us, you and your business can avail yourself of the following perks<br />
                        • Competitive prices<br />
                        • Save time by booking trucks online<br />
                        • Get access to 20+ lakh trucks<br />
                        • Monitor your vehicle with real-time GPS tracking<br />
                        • All categories of trucks that serve across PAN-India.</Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')} sx={{ marginBottom: isMatch ? '20px' : '10px' }}>
                <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
                    <Typography sx={typoqus}> How can I make an online Truck booking?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={typoans}>
                        Our booking technology streamlines the procedure. All you need to do is download our DLX app. Add pickup and destination location. Then, select the weight of goods, type, and size of the truck.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')} sx={{ marginBottom: isMatch ? '20px' : '10px' }}>
                <AccordionSummary aria-controls="panel8d-content" id="panel8d-header">
                    <Typography sx={typoqus}> How can I check my Transportation cost?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={typoans}>
                        Our transport company in India has provided our customers with a handy cost estimation tool. Through this, you can easily check your pricing estimation by choosing a
                        location and the size of truck required to transport your goods. If you still have any issues, feel free to reach our experts. </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')} sx={{ marginBottom: isMatch ? '20px' : '10px' }}>
                <AccordionSummary >
                    <Typography sx={typoqus}> What is full truck laod Service?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={typoans}>
                        Full-Truckload services is a truck transportation service needed by SMEs small-to-medium enterprises and large firms who want to transport huge goods and loads across long distances.
                        The whole available space in a truck is allotted to a single customer to transport their goods.</Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')} sx={{ marginBottom: isMatch ? '20px' : '10px' }}>
                <AccordionSummary >
                    <Typography sx={typoqus}> How will I get my invoice and bill?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={typoans}>
                        You will receive your invoice on your registered WhatsApp number, and you can also download it from the app.</Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')} sx={{ marginBottom: isMatch ? '20px' : '10px' }}>
                <AccordionSummary aria-controls="panel8d-content" id="panel8d-header">
                    <Typography sx={typoqus}> Can I place a booking with multi-point pickup and drop?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={typoans}>
                        Yes, through our transport company app, you make
                        a special request at the time of booking and add multi-point as per your requirement. </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')} sx={{ marginBottom: isMatch ? '20px' : '10px' }}>
                <AccordionSummary >
                    <Typography sx={typoqus}> Can I cancel my booking? Is there any cancellation fee?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={typoans}>
                        Yes, you can cancel your booking on the app without any hassle. However,
                        you must cancel your booking before the vehicle reaches the loading point to avoid any cancellation fees.</Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')} sx={{ marginBottom: isMatch ? '20px' : '10px' }}>
                <AccordionSummary >
                    <Typography sx={typoqus}> Can I change the vehicle type after it has reached the pickup point?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={typoans}>
                        No, you can not change the vehicle after it reaches the pickup point.
                        It is advised to choose and select the type of vehicle as per your freight requirements.</Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel14'} onChange={handleChange('panel14')} sx={{ marginBottom: isMatch ? '20px' : '10px' }}>
                <AccordionSummary aria-controls="panel8d-content" id="panel8d-header">
                    <Typography sx={typoqus}> Do you also provide loading /unloading & packing services?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={typoans}>
                        No, we do not provide loading/unloading & packing services. After booking, our verified truck operators will
                        reach the pickup points for loading.</Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel15'} onChange={handleChange('panel15')} sx={{ marginBottom: isMatch ? '20px' : '10px' }}>
                <AccordionSummary >
                    <Typography sx={typoqus}> Are there any additional charges or waiting charges?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={typoans}>
                        In case the loading of the vehicle exceeds 24 hours, then detention charges are charged. To avoid it,
                        you need to load the truck within 24 hours when the truck reaches the pickup point.</Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel16'} onChange={handleChange('panel16')} sx={{ marginBottom: isMatch ? '20px' : '10px' }}>
                <AccordionSummary >
                    <Typography sx={typoqus}> What type of truck do you have?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={typoans}>
                        Here is the list of trucks we have to transport your goods from Delhi NCR to PAN India:<br /><br />
                        • 32 ft SXL Container Trucks<br />
                        • 32 ft MXL Container Trucks<br />
                        • 14-24ft Container Trucks<br />
                        • 14-22 ft Open Trucks<br />
                        • 10 tyre/ 12 tyre/ 14 tyre Open Trucks • Pickup Trucks<br />
                        • Trailer Trucks
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel17'} onChange={handleChange('panel17')} sx={{ marginBottom: isMatch ? '20px' : '10px' }}>
                <AccordionSummary aria-controls="panel8d-content" id="panel8d-header">
                    <Typography sx={typoqus}> When do I have to make a payment?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={typoans}>
                        Once the vehicle reaches the loading point, you will have to make the full payment of your goods transportation services through the app
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel18'} onChange={handleChange('panel18')} sx={{ marginBottom: isMatch ? '20px' : '10px' }}>
                <AccordionSummary >
                    <Typography sx={typoqus}> How can I track my vehicle?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={typoans}>
                        We provide real-time GPS tracking through our DLX truck booking app.
                        You can anytime track your vehicle within a few clicks.</Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel19'} onChange={handleChange('panel19')} sx={{ marginBottom: isMatch ? '20px' : '10px' }}>
                <AccordionSummary >
                    <Typography sx={typoqus}>What type of material types can I transport ?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={typoans}>
                        We deliver all types of goods, except oil and perishable items, bio hazardous,
                        nuclear waste, and government banned products/ materials. </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel20'} onChange={handleChange('panel20')} sx={{ marginBottom: isMatch ? '20px' : '10px' }}>
                <AccordionSummary >
                    <Typography sx={typoqus}>What are the payment methods available?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={typoans}>
                        We accept payment transfers via<br/><br/>
                        • NEFT<br/>
                        • IMPS<br/>
                        • Net Banking <br/>
                        • UPI<br/>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel21'} onChange={handleChange('panel21')} sx={{ marginBottom: isMatch ? '20px' : '10px' }}>
                <AccordionSummary aria-controls="panel8d-content" id="panel8d-header">
                    <Typography sx={typoqus}> How can I contact the driver?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={typoans}>
                    You can get a call driver option on our truck booking app which you can directly
                     contact the driver without any hassle</Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel22'} onChange={handleChange('panel22')} sx={{ marginBottom: isMatch ? '20px' : '10px' }}>
                <AccordionSummary >
                    <Typography sx={typoqus}> How can I raise a ticket if I have an issue?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={typoans}>
                    In case you have any concerns or are facing any issues, you can easily contact our 
                    x customer care number provided on the app</Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel23'} onChange={handleChange('panel23')} sx={{ marginBottom: isMatch ? '20px' : '10px' }}>
                <AccordionSummary >
                    <Typography sx={typoqus}>Can I check the prices without booking truck?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={typoans}>
                    Yes, through our online booking app, you can easily make a booking in 
                    advance as per your transportation needs. </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel24'} onChange={handleChange('panel24')} sx={{ marginBottom: isMatch ? '20px' : '10px' }}>
                <AccordionSummary >
                    <Typography sx={typoqus}>Can I book a truck in Advance?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography sx={typoans}>
                    Yes, through our online booking app, you can easily make a booking 
                    in advance as per your transportation needs. </Typography>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}

export default Faq1;
