import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import MenuIcon from "@mui/icons-material/Menu";
import costestim from "../assets/svg/costEstim.svg";
import NavHome from "../assets/svg/NavHome.svg";
import NavAboutUs from "../assets/svg/NavaboutUs.svg";
import NavFAQ from "../assets/svg/NavFAQ.svg";
import NavService from "../assets/svg/NavService.svg";
import NavContactus from "../assets/svg/NavContactus.svg";
import { styled } from '@mui/system';

const DrawerComp = () => {
    const [openDrawer, setOpenDrawer] = useState(false);
const navigate=useNavigate();


const StyledList = styled(List)({
    // selected and (selected + hover) states
    '&& .Mui-selected, && .Mui-selected:hover': {
      backgroundColor: 'red',
      '&, & .MuiListItemIcon-root': {
        color: 'pink',
      },
    },
    // hover states
    '& .MuiListItemButton-root:hover': {
      backgroundColor: 'orange',
      '&, & .MuiListItemIcon-root': {
        color: 'yellow',
      },
    },
  });
    return (
        <React.Fragment>

            <IconButton variant="filled"
                sx={{ color: "black", marginLeft: "auto" }}
                onClick={() => setOpenDrawer(!openDrawer)} >
                <MenuIcon />
            </IconButton>
            <Drawer
                PaperProps={{
                    sx: { width: "65%" },
                }}
                anchor="right"
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                

            >
                <StyledList>

                    <ListItemButton >
                        <ListItemIcon onClick={()=>setOpenDrawer(false)}><CloseIcon /> </ListItemIcon>
                        <ListItemText><Typography sx={{fontSize: '15px', fontFamily: 'Poppins', fontWeight: '500' }}>Our Offerings</Typography></ListItemText>
                    </ListItemButton>
                    <Divider />
                    <ListItem onClick={()=>navigate("/")}>
                    <ListItemButton>
                        <ListItemIcon><Box component="img" alt="arrow" src={NavHome} sx={{ height: '30px', width: '30px' }} />
                        </ListItemIcon>
                        <ListItemText> <Typography sx={{fontSize: '15px', fontFamily: 'Poppins', fontWeight: '500' ,height: '25px', width: '54px'}}>Home</Typography> </ListItemText>
                    </ListItemButton>
                    </ListItem>
                    <ListItem onClick={()=>navigate("/services")}>
                    <ListItemButton >
                        <ListItemIcon><Box component="img" alt="arrow" src={NavService} sx={{ height: '30px', width: '35px' }} />
                        </ListItemIcon>
                        <ListItemText><Typography sx={{fontSize: '15px', fontFamily: 'Poppins', fontWeight: '500',height: '25px', width: '74px' }}>Services</Typography></ListItemText>
                    </ListItemButton>
                    </ListItem>

                    <ListItem onClick={()=>navigate("/costestimation")}>
                    <ListItemButton >
                        <ListItemIcon> <Box component="img" alt="arrow" src={costestim} sx={{ height: '26px', width: '26px' }} />
                        </ListItemIcon>
                        <ListItemText><Typography sx={{fontSize: '15px', fontFamily: 'Poppins', fontWeight: '500',height: '25px', width: '143px' }}>Cost Estimation</Typography></ListItemText>
                    </ListItemButton>
                    </ListItem>

                    <ListItem onClick={()=>navigate("/about")}>
                    <ListItemButton >
                        <ListItemIcon><Box component="img" alt="arrow" src={NavAboutUs} sx={{ height: '18px', width: '35px' }} />
                        </ListItemIcon>
                        <ListItemText><Typography sx={{fontSize: '15px', fontFamily: 'Poppins', fontWeight: '500',height: '25px', width: '82px' }}>About Us</Typography></ListItemText>
                    </ListItemButton>
                    </ListItem>

                    <ListItem onClick={()=>navigate("/faq")}>
                    <ListItemButton >
                        <ListItemIcon><Box component="img" alt="arrow" src={NavFAQ} sx={{ height: '30px', width: '30px' }} />
                        </ListItemIcon>
                        <ListItemText><Typography sx={{fontSize: '15px', fontFamily: 'Poppins', fontWeight: '500',height: '25px', width: '54px' }}>FAQ</Typography></ListItemText>
                    </ListItemButton>
                    </ListItem>

                    <ListItem onClick={()=>navigate("/contact")}>
                    <ListItemButton >
                        <ListItemIcon><Box component="img" alt="arrow" src={NavContactus} sx={{ height: '30px', width: '30px' }} />
                        </ListItemIcon>
                        <ListItemText><Typography sx={{fontSize: '15px', fontFamily: 'Poppins', fontWeight: '500',height: '25px', width: '101px' }}>Contact Us</Typography></ListItemText>
                     </ListItemButton>
                    </ListItem>
                </StyledList>
            </Drawer>

        </React.Fragment>
    );
};

export default DrawerComp;
