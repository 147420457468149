import { getData, postData } from "./rest-api-helper"

export async function getAllTrkTypes() {
    const details = {
        urlPath: "/customer/trkTypes"
    }
    let res = await getData(details);

    console.log(res, "---------------getAllTrkTypes");

    if (res) {
        return res.filter((i) => (i.type.vehicle === "Open Body Truck" || i.type.vehicle === "Container"))
    } else {
        return []
    }
}

export async function getDDCities(txt) {

    if (!txt)
        return []

    const details = {
        urlPath: "/services/cities/" + txt
    }

    const res = await getData(details);
    console.log(res, "---------------getDDCities");
    return res || [];
}


export async function calculateRate(data) {
    console.log(data)
    const details = {
        urlPath: "/pricing/calculate",
        body: {
            ...data
        }
    }

    const res = await postData(details);
    console.log(res, "---------------calculateRate");
    return res.finalRate;
}

export async function contactAPI(data) {
    console.log(data)
    const details = {
        urlPath: "/customer/webContact",
        body: {
            ...data
        }
    }

    const res = await postData(details);
    console.log(res, "---------------contactAPI");
    return res;
}