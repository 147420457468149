import { Box, Card, colors, TextField, Typography } from '@mui/material'
import React from 'react'

function CtrlFillLabelTxtField({ name, title, placeholder, type, errMsg, defaultValue, readOnly, disabled, multiline, width, endAdornment, step,
    value, onChange, startAdornment, min, max }) {
    const labelStyle = {
        color: errMsg && colors.red[500],
        fontWeight: "500",
        fontSize: 12,
        fontFamily: 'Poppins'
    }

    return (
        <Card sx={{ width: width ? width : 500 }} >
            <Box sx={{ backgroundColor: "#ffff", borderRadius: 1.4, height: errMsg ? 80 : 50, borderLeft: '5px solid blue' }} px={2} py={1}>

                <Typography color={"primary.main"} htmlFor={title} style={labelStyle} component={'label'} >{title}</Typography>
                <TextField variant="filled"
                    inputProps={{
                        step: step,
                        min: min,
                        max: max
                    }}
                    InputProps={{
                        disableUnderline: true, style: { paddingBottom: 15 },
                        readOnly: readOnly,
                        disabled: disabled,
                        multiline: multiline,
                        endAdornment: endAdornment,
                        startAdornment: startAdornment
                    }}
                    sx={{
                        "& .MuiInputBase-root": {
                            height: 38,
                            backgroundColor: "white",
                            fontSize: 12,fontWeight: "500",
                            fontFamily: 'Poppins'
                        },
                        mt: 0.1,

                    }}
                    fullWidth size="small" placeholder={placeholder ? placeholder : title} name={name} type={type} defaultValue={defaultValue}
                    id={title}
                    error={errMsg && true}
                    helperText={errMsg && errMsg}
                    value={value}
                    onChange={onChange}
                />

            </Box>
        </Card>
    )
}

export default CtrlFillLabelTxtField