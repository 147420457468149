import React from 'react';
import { Typography, Box, Stack, Card, Grid, useMediaQuery } from '@mui/material';
import aboutUs from '../assets/images/aboutUs.png';
import delivery from '../assets/images/delivery.png';
import arrowicon1 from '../assets/images/arrowIcon1.png';
import arrowIcon from '../assets/images/arrowIcon2.png';
import truck from '../assets/svg/truck.svg';
import trolley from '../assets/images/Group99.png';
import newdlxcommit from '../assets/svg/dlx commitment.svg';
//import DLXcommitmobile from '../assets/svg/mobiledlxcommit.svg';

import DLXcommitmobile from '../assets/images/dlx commitmentmobilenew.png';
import Footer from '../components/Footer';

function AboutUsPage() {
  const isMatch = useMediaQuery("(min-width:650px)")
  const isMatch1 = useMediaQuery("(max-width:390px)")
  return (
    <>
      <Stack style={{ backgroundImage: `url(${aboutUs})`, backgroundSize: '100% 100%', backgroundPosition: 'center', width: isMatch ? '1420px' : '385px', height: isMatch ? '380px' : '170px' }}>
        <Typography sx={{ fontSize: isMatch ? "50px" : "22px", color: "#fff", justifyContent: "left", pt: isMatch ? 38 : 15, paddingLeft: isMatch ? "100px" : "20px", fontFamily: 'Rajdhani', fontWeight: 600 }}>About us</Typography>
      </Stack>
      <Stack display={{ xs: 'none', lg: 'flex', md: 'flex',sm:'none' }}>
        <Typography marginLeft={isMatch ? "7rem" : "3rem"} sx={{ fontFamily: "Poppins", color: "#202020", fontSize: isMatch ? '40px' : '16px', fontWeight: 500, marginBottom: '10px', marginTop: '25px' }}>DLX: A Decade of Excellence in Logistics</Typography>
      </Stack>
      <Stack paddingBottom={'0px'} marginLeft={isMatch ? "6.25rem" : "1rem"} paddingTop={isMatch ? '0px' : '15px'}>
        <Stack direction={isMatch ? "row-reverse" : "column"} alignItems="center" spacing={4} >
          <Box component="img" alt="delivery" src={delivery} sx={{ width: isMatch ? '550px' : '250px', height: isMatch ? '376px' : '170px', marginRight: isMatch ? '100px' : '0px' }} />

          <Stack display={{ lg: 'none', md: 'none',xs:'flex',sm:'flex' }}>
            <Typography sx={{ fontFamily: "Poppins", color: "#202020", fontSize: isMatch ? '40px' : '16px', fontWeight: 500, marginBottom: '10px' }}>DLX: A Decade of Excellence in Logistics</Typography>
          </Stack>

          <Stack width={{ xs: '100%', md: '70%', lg: '70%' }}  >
            <Typography sx={{ fontFamily: "Poppins", color: "#202020", fontSize: isMatch ? '16px' : '12px', marginBottom: '20px', fontWeight: 100, marginRight: '25px' }}>
              &emsp;&emsp;&emsp;&emsp;&emsp;At DLX, we stand at the forefront of logistics innovation with over a decade of industry expertise.
              Our journey is marked by a steadfast commitment to delivering unmatched logistics solutions, tailored to a diverse
              spectrum of shipping needs. What sets us apart is not just our proven track record, but our deep dedication to customer
              satisfaction and meticulous attention to every detail. Choosing DLX means entrusting your logistics needs to a
              team of seasoned professionals, dedicated to handling your shipments with unparalleled care and precision.
              Experience the DLX difference, where every delivery is a testament to our expertise and passion for logistics.
            </Typography>
            <Typography sx={{ fontFamily: "Poppins", color: "#202020", fontSize: isMatch ? '16px' : '12px', marginTop: isMatch ? '20px' : '10px', marginRight: '25px' }}>
              &emsp;&emsp;&emsp;&emsp;&emsp;Our commitment to customer satisfaction and attention to detail sets us apart from the rest. When you choose DLX, you can trust that your shipments are in the hands of experienced professionals who will handle them with the utmost care and precision.
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <Grid container spacing={isMatch ? 3 : 6} paddingTop={isMatch ? '60px' : '60px'} columnSpacing={isMatch1 ? 1 : 0} >
        <Stack direction={"row"} marginTop={"30px"} marginLeft={isMatch ? "120px" : "45px"} marginRight={isMatch ? '30px' : '5px'}>
          <Card sx={{ backgroundColor: '#ffffff', color: '#202020', p: isMatch ? 3 : 2, width: isMatch ? '210px' : '150px', height: isMatch ? '160px' : '125px', borderRadius: '0px', marginLeft: '10px' }}>
            <Box component="img" alt="arrow" src={trolley} sx={{ height: isMatch ? '32px' : '22px', width: isMatch ? '42px' : '30px' }} />
            <Typography sx={{ fontFamily: "Poppins", color: "#202020", fontSize: isMatch ? '18px' : '12px', fontWeight: 700, }}>Affordable Price</Typography>
            <Typography marginTop={"10px"} sx={{ fontFamily: "Rubik", fontSize: isMatch ? '16px' : '11px' }}>	Competitive pricing and cost savings through route optimization Typography</Typography>
          </Card>
        </Stack>
        <Stack direction={"row"} marginTop={"30px"} marginLeft={isMatch1 ? "45px" : "45px"} marginRight={isMatch ? '30px' : '0px'}>
          <Card sx={{ backgroundColor: '#ffffff', color: '#202020', p: isMatch ? 3 : 2, width: isMatch ? '200px' : '150px', height: isMatch ? '160px' : '125px', borderRadius: '0px', marginLeft: '10px' }}>
            <Box component="img" alt="arrow" src={truck} sx={{ height: isMatch ? '31px' : '22px', width: isMatch ? '40px' : '30px' }} />
            <Typography sx={{ fontFamily: "Rubik", color: "#202020", fontSize: isMatch ? '18px' : '12px', fontWeight: 600, }}>Care & Secure</Typography>
            <Typography marginTop={"10px"} sx={{ fontFamily: "Rubik", fontSize: isMatch ? '16px' : '11px' }}>Safe and secure transportation of goods.</Typography>
          </Card>
        </Stack>
        <Stack direction={"row"} marginTop={"30px"} marginLeft={isMatch1 ? "55px" : "15px"} marginRight={isMatch ? '30px' : '2px'}>
          <Card sx={{ backgroundColor: '#ffffff', color: '#202020', p: isMatch ? 3 : 2, width: isMatch ? '200px' : '150px', height: isMatch ? '160px' : '125px', borderRadius: '0px' }}>
            <Box component="img" alt="arrow" src={arrowIcon} sx={{ height: isMatch ? '31px' : '22px', width: isMatch ? '40px' : '30px' }} />
            <Typography sx={{ fontFamily: "Rubik", color: "#202020", fontSize: isMatch ? '18px' : '12px', fontWeight: 600, }}>Time Delivery</Typography>
            <Typography marginTop={"10px"} sx={{ fontFamily: "Rubik", fontSize: isMatch ? '16px' : '11px' }}>	Reliable and timely<br /> delivery of shipments</Typography>
          </Card>
        </Stack>

        <Stack direction={"row"} marginTop={"30px"} marginLeft={isMatch1 ? "45px" : "10px"} marginRight={isMatch ? '30px' : '2px'}>
          <Card sx={{ backgroundColor: '#ffffff', color: '#202020', p: isMatch ? 3 : 2, width: isMatch ? '200px' : '150px', height: isMatch ? '160px' : '125px', borderRadius: '0px', marginLeft: '10px' }}>
            <Box component="img" alt="arrow" src={arrowicon1} sx={{ height: isMatch ? '32px' : '22px', width: isMatch ? '40px' : '30px' }} />

            <Typography sx={{ fontFamily: "Rubik", color: "#202020", fontSize: isMatch ? '18px' : '12px', fontWeight: 600, }}>Customer Support</Typography>
            <Typography marginTop={"10px"} sx={{ fontFamily: "Rubik", fontSize: isMatch ? '16px' : '11px' }}>Efficient and transparent communication with customers</Typography>
          </Card>
        </Stack>
      </Grid>
      <Stack display={{ xs: 'none', lg: 'flex' }} marginLeft={{ xs: '40px', md: '100px' }} marginTop={{ xs: '20px', md: '70px' }} marginBottom={{ xs: '10px', md: '50px' }}>
        <img src={newdlxcommit} alt="google store" style={{ width: '90%', marginTop: { xs: '10px' } }} />
      </Stack>

      <Stack display={{ lg: 'none', xs: 'flex' }} marginLeft={{ xs: '0px' }} marginTop={{ xs: '20px' }} marginBottom={{ xs: '30px' }}>
        <img src={DLXcommitmobile} alt="google store" style={{ width: '90%', marginTop: '30px' }} />
      </Stack>

      <Footer />
    </>
  );
}

export default AboutUsPage;

