import React, { useRef } from "react";
import Slider from "react-slick";

import partner1 from '../assets/svg/ideal logo 1.svg';
import partner2 from '../assets/svg/ponnudurai logo 1.svg';
import partner3 from '../assets/svg/regent 1.svg';
import partner4 from '../assets/svg/shelton logo 1.svg';
import partner5 from '../assets/svg/vijayalakhmi store logo 1.svg';


function MobilePartner() {
  
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000
  };
  return (
    <div className="slider-container">
      <Slider  {...settings}>
        <div >
          <img src={partner1} style={{ width: '180px', height: '180px'}} />
          
        </div>
        <div>
        <img src={partner2} style={{ width: '180px', height: '180px'}}/>
        </div>
        <div>
        <img src={partner3} style={{ width: '180px', height: '180px'}}/>
        </div>
        <div>
        <img src={partner4} style={{ width: '180px', height: '180px'}}/>
        </div>
        <div>
        <img src={partner5} style={{ width: '180px', height: '180px'}}/>
        </div>
      </Slider>
    </div>
  );
}
export default MobilePartner;


