import React, { useState } from 'react';
import { Stack, ToggleButton, ToggleButtonGroup, Typography, List, ListItem, ListItemIcon } from '@mui/material';
import consignor from '../assets/images/consigNew.png';
import TruckOpr from '../assets/images/truckoperatorhome.png';
import pointers from '../assets/svg/pointers.svg';

function Benefitmobile() {
  const [value, setValue] = useState('consignee');

  const handleValueChange = (event, newValue) => {
    console.log(event)
    setValue(newValue);
  };
  
  const renderParagraph = () => {
    const commonContentStyles = {
      fontFamily: 'Poppins',
      marginLeft: '5%',
      marginRight: '5%',
      maxWidth: '40rem', // Adjust the maximum width as needed
      width: '90%', // Adjust the width as needed
    };
    
    console.log(value)
    return (
      <Stack
       // direction={{ xs: 'column', md: 'row' }}
        marginTop={'10px'}
        alignItems="center"
        marginLeft={"1.25rem"}
      >
        <img
          src={value === 'consignee' ? consignor : TruckOpr}
          alt={value === 'consignee' ? 'Consignee/Consignor' : 'Truck Operator'}
          width="150px"
          height="172px"

        />
        
        <Stack {...commonContentStyles} width={{ xs: '100%', md: '50%' }}>
        <Typography sx={{ color: '#5C21DB', fontSize: '14px',fontFamily:'Poppins',fontWeight: '500',marginLeft:'20px' }}>Benefit of</Typography>
          <Typography sx={{ fontSize: '14px', fontWeight: '500',marginLeft:'20px',fontFamily:'Poppins'  }}>
            
            {value === 'consignee' ? 'CONSIGNOR/CONSIGNEE:' : 'TRUCK OPERATORS:'}
          </Typography>
          <List>
            <List>
              <ListItem>
                <ListItemIcon>
                  <img src={pointers} alt="pointers" width={'18px'} height={'16px'} fontWeight={1500} />
                </ListItemIcon>
                <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px', marginRight: '30px' }}>
                  {value === 'consignee' & value!=='null'? 
                  (<><b ><u> Faster Delivery:</u></b>  with real-time tracking</>) : 
                  (<><b><u> Reduced Idle Time: </u></b> Minimize idle time and increase earnings.</> )}
                </Typography>
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <img src={pointers} alt="pointers" width={'18px'} height={'16px'} />
                </ListItemIcon>
                <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px', marginRight: '30px' }} >
                  {value === 'consignee' ? (
                    <> <b><u> Cost Savings: </u></b>  through competitive pricing & to optimize routes</>) :
                    (<><b><u> Best Payment Policy: </u></b>  Timely and transparent payment policy.</>)}
                </Typography>
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <img src={pointers} alt="pointers" width={'18px'} height={'16px'} />
                </ListItemIcon>
                <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px', marginRight: '30px' }} >
                  {value === 'consignee' ? (
                    <><b><u>Insured shipments :</u></b>for reduced risk</>) : (<><b><u>Relay Load: </u></b>Maximize efficiency with relay load options.</>)}</Typography>
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <img src={pointers} alt="pointers" width={'18px'} height={'16px'} />
                </ListItemIcon>
                <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px', marginRight: '30px' }} >
                  {value === 'consignee' ? (<><b><u>100% verified trucks: </u></b>network for safe and reliable transportation</>) : 
                  (<><b><u>Verified Customer: </u></b>Guaranteed safe and hassle free-mindset.</>)}</Typography>
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <img src={pointers} alt="pointers" width={'18px'} height={'16px'} />
                </ListItemIcon>
                <Typography sx={{ fontFamily: 'Poppins', fontSize: '12px', marginRight: '30px' }} >
                  {value === 'consignee' ?
                    (<> <b><u>Improved customer service: </u></b> by enabling faster & meet individual needs.</>) : (<> <b><u>Reliable Support: </u></b>24/7 customer service for smooth operations.</>)}
                </Typography>
              </ListItem>
            </List>
          </List>

        </Stack>
      </Stack>
    );
  };

  return (
    <>
      <ToggleButtonGroup
        value={value}
        exclusive
        onChange={handleValueChange}
        aria-label="toggle buttons group"
        sx={{
          marginBottom: '20px',
          marginTop: '30px',
        }}
      >
        {/* ToggleButton styles are adjusted for responsiveness */}
        <ToggleButton
          value="consignee"
          sx={{
            width: '53%', // Full width on small screens
            height: 'auto',
            fontSize: '16px',
            color: value === 'consignee' ? 'white' : 'black',
            backgroundColor: value === 'consignee' ? '#5611DF !important' : 'white',
            '&:hover': {
              backgroundColor: value === 'consignee' ? '#5611DF !important' : '#F5F5F5',
            },
            '&.Mui-selected': {
              color: 'white',
              backgroundColor: '#5611DF !important',
              '&:hover': {
                backgroundColor: '#5611DF !important',
              },
            },
          }}
        >
          Consignor
        </ToggleButton>
        {/* ToggleButton styles are adjusted for responsiveness */}
        <ToggleButton
          value="truck-operator"
          sx={{
            width: '50%', // Full width on small screens
            height: 'auto',
            fontSize: '16px',
            color: value === 'truck-operator' ? 'white' : 'black',
            backgroundColor: value === 'truck-operator' ? '#5611DF !important' : 'white',
            '&:hover': {
              backgroundColor: value === 'truck-operator' ? '#5611DF !important' : '#F5F5F5',
            },
            '&.Mui-selected': {
              color: 'white',
              backgroundColor: '#5611DF !important',
              '&:hover': {
                backgroundColor: '#5611DF !important',
              },
            },
          }}
        >
          Truck Operator
        </ToggleButton>
      </ToggleButtonGroup>
      {renderParagraph()}
    </>
  );
}

export default Benefitmobile;