import React, {  useRef } from 'react';
import { AppBar, Toolbar, Typography, Box, ButtonBase, useMediaQuery, Stack, useTheme, Grid, Button } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import logo from '../../assets/svg/logo.svg';
import './navbar.css';
import DrawerComp from '../Drawer';

import { useNavigate } from "react-router-dom";
import MyDrawer from '../Drawer';
import Footer from '../Footer';

function Navbar() {

const navigate=useNavigate();
  const theme = useTheme();
  const isLarge = useMediaQuery('(min-width: 960px)'); // Adjust the breakpoint according to your design
  const isMedium = useMediaQuery(useTheme().breakpoints.up('md'));
  const location = useLocation();
  //const isMatch = useMediaQuery(theme.breakpoints.down("sm"));
 const isMatch=useMediaQuery("(max-width: 650px)");
 const isMatch1=useMediaQuery("(max-width: 360px)");
 const paragraphRef = useRef(null);
  
 console.log(isMatch)
 
 async function handleClick() {
  window.scroll({
    top: document.body.offsetHeight,
    left: 0, 
    behavior: 'smooth',
  });
}
  return (
    <Box flexGrow={1}>
      <AppBar position='static' color='common' elevation={0} sx={{ borderBottom: 1, borderColor: "#E3E3E3" }}>
        <Toolbar variant="dense">
          <Box mr={!isLarge && 10}>
            <ButtonBase component={"div"} onClick={()=>navigate("/")}>
              <Box component="img" alt="Company Logo" src={logo} sx={{ height: '64px', width:isMatch1?"80px": '100px' }} />
            </ButtonBase>
          </Box>
          {isMatch ? (
            <>
            <Button onClick={handleClick} style={{ background: 'red', color: 'white', borderRadius: '20px' , width:"550px",fontSize:"12px",fontFamily:"Poppins",fontWeight:"500px"}}>Download App</Button>
            
              <MyDrawer/>
            </>
          ) : (

          <Box
            sx={{
              display: 'flex',
              flexDirection: isLarge ? 'row' : 'column',
              alignItems: isLarge ? 'center' : 'flex-start',
              marginLeft: 'auto', // Push the Box to the left
            }}
          >
            <NavLink to="/" exact={true} className="nav-link" activeClassName="nav-link-active">
              <Typography variant="h6" sx={{
                fontFamily: 'Poppins',
                fontWeight: '500',
                fontSize: '18px',
                marginLeft: isLarge ? '0px' : 0,
                marginBottom: isMedium ? 0 : '10px',
                color: location.pathname === '/' ? 'blue' : 'black',
              }}>HOME</Typography>
            </NavLink>
            <NavLink to="/services" className="nav-link" activeClassName="nav-link-active">
              <Typography variant="h6" sx={{
                fontFamily: 'Poppins',
                fontWeight: '500',
                fontSize: '18px',
                marginLeft: isLarge ? '0px' : 0,
                marginBottom: isMedium ? 0 : '10px',
                color: location.pathname === '/services' ? 'blue' : 'black',
              }}>SERVICES</Typography>
            </NavLink>
            <NavLink to="/costestimation" className="nav-link" activeClassName="nav-link-active">
              <Typography variant="h6" sx={{
                fontFamily: 'Poppins',
                fontWeight: '500',
                fontSize: '18px',
                marginLeft: isLarge ? '0px' : 0,
                marginBottom: isMedium ? 0 : '10px',
                color: location.pathname === '/costestimation' ? 'blue' : 'black',
              }}>COST ESTIMATION</Typography>
            </NavLink>
            <NavLink to="/about" className="nav-link" activeClassName="nav-link-active">
              <Typography variant="h6" sx={{
                fontFamily: 'Poppins',
                fontWeight: '500',
                fontSize: '18px',
                marginLeft: isLarge ? '0px' : 0,
                marginBottom: isMedium ? 0 : '10px',
                color: location.pathname === '/about' ? 'blue' : 'black',
              }}>ABOUT US</Typography>
            </NavLink>
            <NavLink to="/faq" className="nav-link" activeClassName="nav-link-active">
              <Typography variant="h6" sx={{
                fontFamily: 'Poppins',
                fontWeight: '500',
                fontSize: '18px',
                marginLeft: isLarge ? '0px' : 0,
                marginBottom: isMedium ? 0 : '10px',
                color: location.pathname === '/faq' ? 'blue' : 'black',
              }}>FAQ</Typography>
            </NavLink>
            <NavLink to="/contact" className="nav-link" activeClassName="nav-link-active">
              <Typography variant="h6" sx={{
                fontFamily: 'Poppins',
                fontWeight: '500',
                fontSize: '18px',
                marginLeft: isLarge ? '0px' : 0,
                marginBottom: isMedium ? 0 : '10px',
                color: location.pathname === '/contact' ? 'blue' : 'black',
              }}>CONTACT US</Typography>
            </NavLink>
            <Button onClick={handleClick} style={{ background: 'red', color: 'white', borderRadius: '20px' ,fontFamily: 'Poppins',fontWeight: '600', fontSize: '14px'}}>DOWNLOAD APP</Button>
          </Box>)}
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Navbar;

