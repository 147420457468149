import React from 'react';
import { Box } from '@mui/material';



function PrivacyPolicy() {
  return (

    <Box width={"100%"} height={"85vh"}>
      <iframe style={{ width: "100%", height: "100%" }} src={"privacyPolicyHtml.html"}></iframe>
    </Box>

  );
}

export default PrivacyPolicy;